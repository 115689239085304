// Generated by Framer (8857874)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./HeedxDsP2-0.js";

const cycleOrder = ["yT00mPcMG"];

const serializationHash = "framer-rXM8q"

const variantClassNames = {yT00mPcMG: "framer-v-1spvjuv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {A55YVbfVC: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yT00mPcMG", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1spvjuv", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yT00mPcMG"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-17661574-4735-4924-aa9b-eca669bb69a4, rgb(255, 255, 255))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rXM8q.framer-1gqp825, .framer-rXM8q .framer-1gqp825 { display: block; }", ".framer-rXM8q.framer-1spvjuv { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 8px; height: 442px; justify-content: flex-end; overflow: visible; padding: 32px; position: relative; width: 444px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rXM8q.framer-1spvjuv { gap: 0px; } .framer-rXM8q.framer-1spvjuv > * { margin: 0px; margin-bottom: calc(8px / 2); margin-top: calc(8px / 2); } .framer-rXM8q.framer-1spvjuv > :first-child { margin-top: 0px; } .framer-rXM8q.framer-1spvjuv > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 442
 * @framerIntrinsicWidth 444
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHeedxDsP2: React.ComponentType<Props> = withCSS(Component, css, "framer-rXM8q") as typeof Component;
export default FramerHeedxDsP2;

FramerHeedxDsP2.displayName = "Feature: Digital Signing";

FramerHeedxDsP2.defaultProps = {height: 442, width: 444};

addFonts(FramerHeedxDsP2, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})